import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import CounterTwo from "../../components/counter";
import "tiny-slider/dist/tiny-slider.css";

import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import { getProductById } from "../../utils/referencedata.services";
import { RiShoppingCartFill } from "react-icons/ri";
import { Helmet } from "react-helmet-async";

export default function PharmacyProductDetail({ addToCart }) {
  // Get the base URL from environment variables
  const baseUrl =
    process.env.REACT_APP_frontoffice_base || window.location.origin;
  const canonicalUrl = `${baseUrl}${window.location.pathname}`;

  // Variables
  let params = useParams();
  let productId = params.productId;
  let [product, setProduct] = useState({});
  let [productCount, setProductCount] = useState(0);
  const [isSuccessful, setIsSuccessful] = useState(false);

  // Use Effect
  useEffect(() => {
    getProductById(productId).then((response) => {
      if (response.data) {
        setProduct(response.data);
      }
    });
  }, [productId]);

  let handleAddToCart = () => {
    addToCart(productId, product, productCount);
    setIsSuccessful(true);
    setTimeout(() => {
      setIsSuccessful(false);
    }, 1000); // hide the banner after 3 seconds
  };

  return (
    <Fragment>
      <Helmet prioritizeSeoTags>
        <title>{`ZipLaneRx -- Shop Curated Wellness Products`}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          property="og:title"
          content="ZipLaneRx -- Redefining healthcare access and practitioner success!"
        />
      </Helmet>

      <Header />

      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundColor: "#202842" }}
      >
        <h1 style={{ opacity: "0" }}>
          ZipLaneRx -- Shop Curated Wellness Products
        </h1>
        <nav aria-label="breadcrumb" className="d-inline-block mt-3">
          <ul className="breadcrumb bg-light rounded mb-0 bg-transparent">
            <li className="breadcrumb-item">
              <Link to="/">ZipLaneRx</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/pharmacy">Pharmacy</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Product Detail
            </li>
          </ul>
        </nav>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            {product?.image ? (
              <div className="col-md-5">
                <img
                  src={`data:image/gif;base64,${product.image}`}
                  className="img-fluid rounded"
                  alt={`ZipLaneRx -- ${
                    product?.labels_fulldescription
                      ? product.labels_fulldescription
                      : ""
                  }`}
                />
              </div>
            ) : (
              <div className="col-md-5"></div>
            )}

            <div className="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-md-4">
                <h4 className="title">
                  {product?.labels_fulldescription
                    ? product.labels_fulldescription
                    : ""}
                </h4>
                <h5 className="text-muted">
                  {product?.price_trade ? `£ ${product.price_trade}` : ""}
                </h5>

                {product.ingredients && product.ingredients != "NaN" ? (
                  <div>
                    <h5 className="mt-4 py-2">Overview :</h5>
                    <p className="text-muted">{product.ingredients}</p>
                  </div>
                ) : null}

                <div className="d-flex shop-list align-items-center justify-content-center">
                  <h6 className="mb-0" style={{ marginRight: 10 }}>
                    Quantity:
                  </h6>{" "}
                  <CounterTwo setProductCount={setProductCount} />
                  <Link
                    to="#"
                    className="btn btn-soft-primary ms-2"
                    onClick={handleAddToCart}
                  >
                    Add to Cart
                  </Link>
                  <Link
                    to="/shop/cart"
                    className="btn btn-soft-primary ms-2 btn-icon"
                  >
                    <RiShoppingCartFill />
                  </Link>
                </div>
                {isSuccessful && (
                  <div
                    className="bg-primary rounded mt-0"
                    style={{
                      color: "white",
                      padding: "10px",
                      position: "relative",
                      top: "0",
                      left: "0",
                      width: "100%",
                      textAlign: "center",
                      zIndex: "1000",
                    }}
                  >
                    Added
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
