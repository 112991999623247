// header component
import React, { useEffect, useState } from "react";
import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import { createCustomerLogic } from "../../utils/usermgmt.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { LiaShoppingCartSolid, LiaUser } from "react-icons/lia";

const Header = () => {
  // Variables
  const navigate = useNavigate();
  const auth0 = useAuth0();
  let [show, setShow] = useState(false);
  let [showTwo, setShowTwo] = useState(false);
  let [scroll, setScroll] = useState(false);
  let [isMenu, setisMenu] = useState(false);
  let [modal, setModal] = useState(false);
  let handleClose = () => setShow(false);
  let handleShow = () => setShow(true);
  let handleCloseTwo = () => setShowTwo(false);
  let handleShowTwo = () => setShowTwo(true);
  let [manu, setManu] = useState("");
  let location = useLocation();
  let [searchString, setSearchString] = useState(null);

  // Use Effects
  useEffect(() => {
    let current = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
    );
    setManu(current);

    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });

    window.scrollTo(0, 0);

    const closeModal = () => {
      setModal(false);
    };

    document.addEventListener("mousedown", closeModal);

    return () => {
      document.removeEventListener("mousedown", closeModal);
    };
  }, []);

  // Handlers
  // Functions
  let toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation").getElementsByTagName("a"),
      );
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        });
      });
    }
  };

  // Variables

  // Access Token and Create Customer Logic
  if (
    auth0.isAuthenticated &&
    window.location.pathname !== "/patient-profile"
  ) {
    if (!sessionStorage.getItem("_accessToken")) {
      // Get Token, Then Save Token, Then Create Customer
      auth0.getAccessTokenSilently().then((res) => {
        // Save Token in Session
        sessionStorage.setItem("_accessToken", res.toString());
        // Create Customer Logic
        if (!sessionStorage.getItem("customerId")) {
          createCustomerLogic(auth0.user).then(() => {});
        }
      });
    } else {
      // Create Customer Logic
      if (!sessionStorage.getItem("customerId")) {
        createCustomerLogic(auth0.user).then(() => {});
      }
    }
  }

  return (
    <header
      id="topnav"
      className={`${scroll ? "nav-sticky" : ""} navigation sticky`}
    >
      <div className="container">
        <div>
          <Link className="logo" to="/home">
            <span className="logo-light-mode">
              <img
                src={"assets/images/logo-icon.png"}
                className="l-dark"
                height="100"
                alt="ZipLaneRx -- Redefining healthcare access and practitioner success!"
              />
              <img
                src={"assets/images/logo-icon.png"}
                className="l-light"
                height="100"
                alt="ZipLaneRx -- Redefining healthcare access and practitioner success!"
              />
            </span>
            <img
              src={"assets/images/logo-icon.png"}
              height="22"
              className="logo-dark-mode"
              alt="ZipLaneRx -- Redefining healthcare access and practitioner success!"
            />
          </Link>
        </div>

        {/*Toggle*/}
        <div className="menu-extras">
          <div className="menu-item">
            <Link
              to="#"
              className={`navbar-toggle ${isMenu ? "open" : ""}`}
              id="isToggle"
              onClick={() => toggleMenu()}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        {/*Search Button and Profile Dropdown*/}
        <ul className="dropdowns list-inline mb-0">
          <li className="list-inline-item mb-0 ms-1">
            <div className="">
              <button
                type="button"
                className="btn btn-pills px-2 py-0 btn-muted"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => {
                  navigate("/shop/cart");
                }}
              >
                <LiaShoppingCartSolid className="avatar avatar-ex-small rounded-circle" />
                Cart
              </button>
            </div>
          </li>
          {/* TODO: Search*/}
          {/*<li className="list-inline-item mb-0 ms-1">*/}
          {/*  <Link*/}
          {/*    to="#"*/}
          {/*    className="btn btn-icon btn-pills btn-primary"*/}
          {/*    onClick={handleShow}*/}
          {/*    title="Search"*/}
          {/*  >*/}
          {/*    <FiSearch />*/}
          {/*  </Link>*/}
          {/*</li>*/}
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="top"
            style={{ height: "250px" }}
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body className="pb-3">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="text-center">
                      <h4>Search now.....</h4>
                      <div className="subcribe-form mt-4">
                        <form>
                          <div className="mb-0">
                            <input
                              type="text"
                              id="help"
                              name="name"
                              className="border rounded-pill"
                              required=""
                              placeholder="Search"
                              onChange={(e) => {
                                setSearchString(e.target.value);
                              }}
                              onSubmit={() => {}}
                              onKeyDown={(key) => {
                                if (key.key === "Enter") {
                                  key.preventDefault();
                                }
                              }}
                            />
                            <Link
                              type="button"
                              className="btn btn-pills btn-primary"
                              to={`/shop/search?name=${searchString}`}
                            >
                              Search
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          {/*Profile Dropdown*/}
          <li className="list-inline-item mb-0 ms-1">
            <div className="dropdown dropdown-primary">
              {/*Profile Picture*/}
              <button
                type="button"
                className="btn btn-pills dropdown-toggle px-2 py-0 btn-muted"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {auth0.user?.picture ? (
                  <img
                    src={auth0.user?.picture}
                    className="avatar avatar-ex-small rounded-circle"
                    alt="ZipLaneRx - User"
                  />
                ) : (
                  <LiaUser className="avatar avatar-ex-small rounded-circle" />
                )}
                User
              </button>

              <div
                className="dropdown-menu dd-menu dropdown-menu-end shadow border-0 mt-3 py-3"
                style={{ minWidth: 200 }}
              >
                {/*Profile Picture and Name*/}
                {auth0?.isAuthenticated ? (
                  <a
                    className="dropdown-item d-flex align-items-center text-dark"
                    href="/patient-profile"
                  >
                    <img
                      src={
                        auth0.user?.picture ||
                        "assets/images/client/Discord.svg"
                      }
                      className="avatar avatar-md-sm rounded-circle border shadow"
                      alt="ZipLaneRx - User"
                    />
                    <div className="flex-1 ms-2">
                      <span className="d-block mb-1">
                        {auth0.user?.nickname || auth0.user?.name}
                      </span>
                      <small className="text-muted">{auth0.user?.email}</small>
                    </div>
                  </a>
                ) : null}

                {/*Profile Settings*/}
                {auth0?.isAuthenticated ? (
                  <a
                    className="dropdown-item text-dark"
                    href="/patient-profile"
                  >
                    <span className="mb-0 d-inline-block me-1">
                      <i className="uil uil-setting align-middle h6"></i>
                    </span>{" "}
                    Profile Settings
                  </a>
                ) : null}

                {/*Login and Logout*/}
                <div className="dropdown-divider border-top"></div>
                {/*Login*/}
                {!auth0.isAuthenticated && (
                  <Link className="dropdown-item text-dark" to="/patient/login">
                    <span className="mb-0 d-inline-block me-1">
                      <i className="uil uil-sign-out-alt align-middle h6"></i>
                    </span>{" "}
                    Patient Login
                  </Link>
                )}

                {/*Logout*/}
                {auth0.isAuthenticated && (
                  <button
                    className="dropdown-item text-dark"
                    onClick={() => {
                      auth0.logout({
                        logoutParams: {
                          returnTo: `${process.env.REACT_APP_frontoffice_base}/home`,
                        },
                      });
                      sessionStorage.clear();
                    }}
                  >
                    <span className="mb-0 d-inline-block me-1">
                      <i className="uil uil-sign-out-alt align-middle h6"></i>
                    </span>{" "}
                    Logout
                  </button>
                )}

                {/*Practitioner Login*/}
                {!auth0.isAuthenticated && (
                    <div className="dropdown-divider border-top"></div>
                  ) && (
                    <button
                      className="dropdown-item text-dark"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_backoffice_base}/sign-in`,
                          "_top",
                        )
                      }
                    >
                      <span className="mb-0 d-inline-block me-1">
                        <i className="uil uil-user-circle align-middle h6"></i>
                      </span>{" "}
                      Practitioner Login
                    </button>
                  )}
              </div>
            </div>
          </li>
        </ul>

        {/*Links*/}
        <div id="navigation" style={{ display: isMenu ? "block" : "none" }}>
          <ul className="navigation-menu nav-left nav-light">
            {/*Home*/}
            <li>
              <Link to="/home" className="sub-menu-item">
                Home
              </Link>
            </li>

            {/*Book*/}
            <li className="has-submenu parent-menu-item">
              <a href="javascript:void(0)">Book Appointments</a>
              <span className="menu-arrow"></span>
              <ul className="submenu">
                <li>
                  <Link to="/book/services" className="sub-menu-item">
                    Pharmacy Services
                  </Link>
                </li>
                <li>
                  <Link to="/book/services/gp" className="sub-menu-item">
                    Private GP Services
                  </Link>
                </li>
                <li>
                  <Link to="/book/services/path" className="sub-menu-item">
                    Private Pathology Services
                  </Link>
                </li>
              </ul>
            </li>

            {/*Shop*/}
            <li className="has-submenu parent-menu-item">
              <a href="javascript:void(0)">Shop</a>
              <span className="menu-arrow"></span>
              <ul className="submenu">
                {/*<li>*/}
                {/*  /!*TODO: change to all*!/*/}
                {/*  <Link to="/home" className="sub-menu-item">*/}
                {/*    all (Coming Soon)*/}
                {/*  </Link>*/}
                {/*</li>*/}
                <li>
                  <Link to="/shop/appliances" className="sub-menu-item">
                    appliances
                  </Link>
                </li>
                <li>
                  <Link to="/shop/cosmetics" className="sub-menu-item">
                    cosmetics
                  </Link>
                </li>
                <li>
                  <Link to="/shop/dressings" className="sub-menu-item">
                    dressings
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shop/electrical-health-diagnostics"
                    className="sub-menu-item"
                  >
                    Electrical Health & Diagnostic
                  </Link>
                </li>
                <li>
                  <Link to="/shop/medical" className="sub-menu-item">
                    medical
                  </Link>
                </li>
                <li>
                  <Link to="/shop/otc" className="sub-menu-item">
                    otc
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shop/pregnancy-and-mothercare"
                    className="sub-menu-item"
                  >
                    Pregnancy & Mothercare
                  </Link>
                </li>
                <li>
                  <Link to="/shop/toiletries" className="sub-menu-item">
                    toiletries
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shop/vitamins-and-supplements"
                    className="sub-menu-item"
                  >
                    Vitamins & Supplements
                  </Link>
                </li>
                {/*<li>*/}
                {/*  <Link to="/shop/test/Fragrances" className="sub-menu-item">*/}
                {/*    fragrance (for test only)*/}
                {/*  </Link>*/}
                {/*</li>*/}
              </ul>
            </li>

            {/*Healthy Living*/}
            <li className="has-submenu parent-menu-item">
              <a href="javascript:void(0)">Health Calculators</a>
              <span className="menu-arrow"></span>
              <ul className="submenu">
                <li>
                  <Link
                    to="/healthy-living/bmi-calculator"
                    className="sub-menu-item"
                  >
                    BMI Calculator
                  </Link>
                </li>
                <li>
                  <Link
                    to="/healthy-living/water-intake-calculator"
                    className="sub-menu-item"
                  >
                    Water Intake Calculator
                  </Link>
                </li>
                <li>
                  <Link
                    to="/healthy-living/bmr-calculator"
                    className="sub-menu-item"
                  >
                    BMR Calculator
                  </Link>
                </li>
                <li>
                  <Link
                    to="/healthy-living/carb-calculator"
                    className="sub-menu-item"
                  >
                    Carb Calculator
                  </Link>
                </li>
                <li>
                  <Link
                    to="/healthy-living/heart-rate-calculator"
                    className="sub-menu-item"
                  >
                    Heart Rate Calculator
                  </Link>
                </li>
                <li>
                  <Link
                    to="/healthy-living/protein-calculator"
                    className="sub-menu-item"
                  >
                    Protein Calculator
                  </Link>
                </li>
              </ul>
            </li>

            {/*Blog*/}
            {/*<li>*/}
            {/*  <Link to="/blogs" className="sub-menu-item">*/}
            {/*    Blog*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default withAuth0(Header);
