// components/InputField.js
import React from "react";

const CartInputField = ({
  type = "text",
  value,
  onChange,
  placeholder,
  required = false,
  validation,
  readOnly = false,
  ariaLabel = null,
  id,
}) => (
  <div className="mb-1 py-1">
    {/*border-top border-left border-right border-bottom rounded*/}
    <input
      type={type}
      id={id}
      className="form-control"
      style={{ borderWidth: "medium" }}
      required={required}
      placeholder={placeholder}
      value={value || ""}
      onChange={onChange}
      readOnly={readOnly}
      disabled={readOnly}
      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      aria-label={ariaLabel}
      aria-required={required}
      aria-invalid={validation ? !validation.valid : false}
    />
    {validation && !validation.valid && (
      <span className="text-danger" style={{ fontSize: "smaller" }}>
        {validation.message}
      </span>
    )}
  </div>
);

export default CartInputField;
