import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  RiCalendar2Fill,
  RiCalendarFill,
  RiCheckboxFill,
  RiCloseFill,
  RiHashtag,
  RiHome2Fill,
  RiMailFill,
  RiMoneyPoundBoxFill,
  RiPhoneFill,
  RiUser2Fill,
} from "react-icons/ri";
import { LuClipboardList, LuClock2 } from "react-icons/lu";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import DatePickerComponent from "../fields/DatePickerComponent";
import TimePickerComponent from "../fields/TimePickerComponent";
import moment from "moment";
import { setHours, setMinutes } from "date-fns";
import { getPractitionerById } from "../../utils/pctmgmt.services";
import usePractitionerData from "../../hooks/usePractitionerData";
import {
  patientCancel,
  rescheduleAppointment,
} from "../../utils/sales.service";

export default function OrdersTabCrud({ item, isMoreThan24hoursAway }) {
  // Variables
  return (
    <>
      <td className="text-left p-3">
        <div className="flex space-x-2">
          {/* Invoice */}

          <Link
            to={`/book/invoice?bookingId=${item.booking_id}`}
            target="_blank"
            className="btn btn-icon btn-primary mx-1"
          >
            <LuClipboardList />
          </Link>
        </div>
      </td>
    </>
  );
}
