import React from "react";
import { Link } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";

function ProductsCartTable({ cart, removeProduct, getItemTotal }) {
  return (
    <div className="row text-start">
      {!cart || Object.keys(cart).length === 0 ? (
        <div className="col-12" style={{ marginTop: 5, marginBottom: 10 }}>
          <h5>Your Products Cart is Empty!</h5>
        </div>
      ) : (
        <div className="col-12" style={{ marginTop: 0, marginBottom: 0 }}>
          <h5>Your Products Cart</h5>
        </div>
      )}

      <div className="col-12">
        <div className="table-responsive bg-white shadow rounded">
          <table className="table table-center table-padding mb-0">
            <thead>
              <tr>
                <th
                  className="border-bottom p-3"
                  style={{ minWidth: "20px" }}
                ></th>
                <th className="border-bottom p-3" style={{ minWidth: "300px" }}>
                  Product
                </th>
                <th className="border-bottom p-3">Price</th>
                <th className="border-bottom p-3">Qty</th>
                <th className="border-bottom p-3">Subtotal</th>
                <th className="border-bottom p-3">VAT-Code</th>
                <th className="border-bottom p-3">VAT</th>
                <th className="border-bottom p-3">Total</th>
              </tr>
            </thead>

            <tbody>
              {Object.values(cart || {}).map((item, index) => {
                if (cart[item.productId].quantity > 0) {
                  return (
                    <tr key={index}>
                      {/*Remove*/}
                      <td className="h5 p-3 text-center">
                        <Link
                          to=""
                          className="text-danger"
                          onClick={() => {
                            removeProduct(item.productId);
                          }}
                        >
                          <LiaTimesSolid />
                        </Link>
                      </td>
                      {/*Product*/}
                      <td className="p-3">
                        <div className="d-flex align-items-center">
                          <img
                            src={`data:image/gif;base64,${item.image}`}
                            className="img-fluid avatar avatar-small rounded shadow"
                            style={{ width: 50, height: 50 }}
                            alt=""
                          />
                          <h6 className="mb-0 ms-3">
                            <Link to={`/shop/products/${item.productId}`}>
                              {item.labels_fulldescription}
                            </Link>
                          </h6>
                        </div>
                      </td>
                      {/*Price*/}
                      <td className="shop-list p-3">
                        {item.price_trade ? `£ ${item.price_trade}` : 0}
                      </td>
                      {/*Quantity*/}
                      <td className="shop-list p-3">
                        {cart[item.productId].quantity}
                      </td>
                      {/*Subtotal*/}
                      <td className="shop-list font-weight-bold p-3">
                        £ {getItemTotal(item)["subtotal"]}
                      </td>
                      {/*VAT Code*/}
                      <td className="shop-list p-3">{item.price_vatcode}</td>
                      {/*VAT AMT*/}
                      <td className="shop-list font-weight-bold p-3">
                        £ {getItemTotal(item)["vatTotal"]}
                      </td>
                      {/*Item Total*/}
                      <td className="shop-list font-weight-bold p-3">
                        £ {getItemTotal(item)["total"]}
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ProductsCartTable;
