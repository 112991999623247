import { PiTelegramLogoBold } from "react-icons/pi";
import { RiHeartLine, RiHourglassLine, RiMailStarLine } from "react-icons/ri";

export const services = [
  {
    name: "NHS Services",
    description:
      "ZipLaneRx, through its extensive practitioner network, brings 20+ NHS services, paid by NHS for our customers. ",
    icon: null,
    image: true,
    imagePath: "assets/images/NHS.png",
  },
  {
    name: "Private Services",
    description:
      "ZipLaneRx works with its partner network of practitioners, to bring 150+ private paid services.",
    icon: "uil uil-pound",
  },
  {
    name: "Health and Advise",
    description:
      "Our customers can avail NHS provided minor ailment and new medicine services",
    icon: "uil uil-heart-medical",
  },
  {
    name: "Contraception Services",
    description: "ZipLaneRx brings Emergency and NHS Contraception services.",
    icon: "uil uil-book-medical",
  },
  {
    name: "Healthy Living Services",
    description:
      "Health check, stop smoking and weight management services for eligible NHS patients, also available as a private service.",
    icon: "ri-run-line",
  },
  {
    name: "Screening and Test Services",
    description:
      "Covid-19, blood pressure and pregnancy testing services for eligible NHS patients, also available as a private service.",
    icon: "ri-user-search-line",
  },
  {
    name: "Dispensing services (Coming Soon)",
    description:
      "Prescription and repeat prescription services are available for in-store pick up or home delivery.",
    icon: "uil uil-tablets",
  },
  {
    name: "Vaccination Services",
    description:
      "ZipLaneRx offers a diverse set of private and NHS paid vaccination services like seasonal flu, travel clinic and more. ",
    icon: "ri-syringe-line",
  },
];

export const servicesInteractiveForm = {
  serv00029: {
    serviceId: "serv00029",
    serviceName: "NHS -WEIGHT MANAGEMENT",
    nhsConsentLink:
      "https://www.england.nhs.uk/digital-weight-management/how-to-access-the-programme/",
    questionnaire: [],
  },
  serv00011: {
    serviceId: "serv00011",
    serviceName: "Sinusitis",
    nhsConsentLink: "https://www.nhs.uk/conditions/sinusitis-sinus-infection/",
    questionnaire: [
      {
        question: "Symptoms for ≤10 days?",
      },
      {
        question: "Symptoms for >10 days?",
      },
    ],
  },
  serv00012: {
    serviceId: "serv00012",
    serviceName: "Sore Throat",
    nhsConsentLink: "https://www.nhs.uk/conditions/sore-throat/",
    questionnaire: [
      {
        question:
          "Does the patient have signs or symptoms indicating possible scarlet fever, quinsy or glandular fever? (refer to NICE CKS for list of symptoms)",
      },
      {
        question:
          "Does the patient have signs and symptoms of suspected cancer?",
      },
      {
        question: "Is the patient immunosuppressed?",
      },
    ],
  },
  serv00006: {
    serviceId: "serv00006",
    serviceName: "Acute Otitis Media",
    nhsConsentLink: "https://www.nhs.uk/conditions/ear-infections/",
    questionnaire: [
      {
        question: "Is the patient between the age of 1 and 17 years?",
      },
      {
        question: "Is the patient systemically very unwell?",
      },
      {
        question: "Has there been signs of a more serious illness?",
      },
      {
        question:
          "Is there a pre-existing comorbidity (this includes children with significant heart, lung, renal, liver or neuromuscular disease, immunosuppression, cystic fibrosis and young children who were born prematurely)?",
      },
    ],
  },
  serv00008: {
    serviceId: "serv00008",
    serviceName: "Infected Insect Bite",
    nhsConsentLink: "https://www.nhs.uk/conditions/insect-bites-and-stings/",
    questionnaire: [
      {
        question: "Bite or scratch caused by animal(s)",
      },
      {
        question: "Bite caused by human(s)",
      },
      {
        question:
          "Bite caused by tick in the UK and signs of Lyme disease such as erythema migrans (bullseye) rash",
      },
      {
        question:
          "Bite or sting that occurred while traveling outside of UK with concern of insect-borne diseases e.g. malaria, tick-borne encephalitis",
      },
      {
        question: "Bite or sting caused by an unusual or exotic insect",
      },
      {
        question: "Redness of skin",
      },
      {
        question: "Pain or tenderness to the area",
      },
      {
        question: "Swelling of skin",
      },
      {
        question: "Skin surrounding the bite feels hot to touch",
      },
    ],
  },
  serv00007: {
    serviceId: "serv00007",
    serviceName: "Impetigo",
    nhsConsentLink: "https://www.bad.org.uk/pils/impetigo/",
    questionnaire: [
      {
        question: "Do you have ≤3 lesions/clusters present?",
      },
      {
        question: "Do you have >4 lesions/clusters present?",
      },
    ],
  },
  serv00010: {
    serviceId: "serv00010",
    serviceName: "Shingles",
    nhsConsentLink: "https://www.bad.org.uk/pils/shingles-herpes-zoster/",
    questionnaire: [
      {
        question: "Immunosuppressed (see below)",
      },
      {
        question:
          "Non-truncal involvement (shingles affecting the neck, limbs, or perineum)",
      },
      {
        question: "Moderate or severe pain",
      },
      {
        question: "Moderate or severe rash (defined as confluent lesions)",
      },
      {
        question: "All patients aged over 50 years",
      },
      {
        question: "Immunosuppressed (see below)",
      },
      {
        question: "Continued vesicle formation",
      },
      {
        question: "Severe pain",
      },
      {
        question:
          "High risk of severe shingles (e.g. severe atopic dermatitis/eczema)",
      },
      {
        question: "All patients aged 70 years and over",
      },
    ],
  },
  serv00013: {
    serviceId: "serv00013",
    serviceName: "Uncomplicated Urinary Tract Infections In Women",
    nhsConsentLink:
      "https://www.nhs.uk/conditions/urinary-tract-infections-utis/",
    questionnaire: [
      {
        question: "Kidney pain/tenderness in back under ribs",
      },
      {
        question: "New/different myalgia, flu like illness",
      },
      {
        question: "Shaking chills (rigors) or temperature 37.9°C or above",
      },
      {
        question: "Nausea/vomiting",
      },
      {
        question: "Vaginal discharge:",
      },
      {
        question: "Urethritis: inflammation post sexual intercourse, irritants",
      },
      {
        question: "Missed or lighter periods",
      },
      {
        question: "Urethritis: inflammation post sexual intercourse, irritants",
      },
      {
        question: "Do you have sexually transmitted infections?",
      },
      {
        question: "Genitourinary syndrome of menopause (vulvovaginal atrophy)",
      },
      {
        question: "Are you immunosuppressed?",
      },
    ],
  },
};

export const medicalServices = [
  {
    icon: "RiEyeFill",
    title: "Eye Care",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiPsychotherapyFill",
    title: "Psychotherapy",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiStethoscopeFill",
    title: "Primary Care",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiCapsuleFill",
    title: "Dental Care",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiMicroscopeFill",
    title: "Orthopedic",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiPulseFill",
    title: "Cardiology",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiEmpathizeFill",
    title: "Gynecology",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiMindMap",
    title: "Neurology",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiMentalHealthLine",
    title: "Dermatologists",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiAppleLine",
    title: "Nutritionists",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
  {
    icon: "RiInfraredThermometerLine",
    title: "Physical Therapists",
    desc: "There is now an abundance of readable dummy texts required purely to fill a space.",
  },
];

export const adminFeature = [
  {
    icon: PiTelegramLogoBold,
    title: "New Messages",
    desc: "Due to its wide spread use as filler text",
  },
  {
    icon: RiMailStarLine,
    title: "Latest Proposals",
    desc: "Due to its wide spread use as filler text",
  },
  {
    icon: RiHourglassLine,
    title: "Package Expiry",
    desc: "Due to its wide spread use as filler text",
  },
  {
    icon: RiHeartLine,
    title: "Saved Items",
    desc: "Due to its wide spread use as filler text",
  },
];

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Aland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const productCategoriesData = {
  all: { image: "assets/images/healthyliving/pills.jpg", name: "All" },
  appliances: {
    image: "assets/images/prod_categories/appliances.jpg",
    name: "Appliances",
  },
  cosmetics: {
    image: "assets/images/prod_categories/cosmetics.jpg",
    name: "Cosmetics",
  },
  dressings: {
    image: "assets/images/prod_categories/dressings.jpg",
    name: "Dressings",
  },
  "electrical-health-diagnostics": {
    image: "assets/images/prod_categories/electric.jpg",
    name: "Electrical Health & Diagnostic",
  },
  medical: { image: "assets/images/healthyliving/pills.jpg", name: "Medical" },
  otc: { image: "assets/images/healthyliving/pills.jpg", name: "OTC" },
  "pregnancy-and-mothercare": {
    image: "assets/images/prod_categories/pregnancy.jpg",
    name: "Pregnancy & Mothercare",
  },
  toiletries: {
    image: "assets/images/prod_categories/toiletries.jpg",
    name: "Toiletries",
  },
  "vitamins-and-supplements": {
    image: "assets/images/healthyliving/pills.jpg",
    name: "Vitamins & Supplements",
  },
};

export const orderDeliveryMethods = [
  {
    value: "free",
    name: "Pick Up",
    desc: "£0.00 / Pick Up at Mango Pharmacy",
    amount: 0,
  },
  {
    value: "standard",
    name: "Standard Delivery",
    desc: "£7.99 / Delivery in 3 to 5 business Days",
    amount: 7.99,
  },
  // {
  //   value: "express",
  //   name: "Express Delivery",
  //   desc: "£9.99 / Delivery in 1 business Days",
  //   amount: 9.99,
  // },
];

export const servicesSuitabilityForm = {
  serv00054: {
    serviceId: "serv00054",
    serviceName: "PHARMACOGENOMICS (PGx) Precision Medicine Services",
    suitable: [
      { question: "Are you taking 5 or more medications?" },
      {
        question: "Are you unhappy with your pain or mental health medication?",
      },
      {
        question:
          "Are you experiencing side effects or have had serious drug reactions?",
      },
      {
        question:
          "Are you frustrated with the trial-and-error prescribing approach?",
      },
      {
        question:
          "Do you want to potentially avoid medicine-related problems in the future?",
      },
    ],
    unsuitable: [
      {
        question:
          "Have you had a history of blood cancer affecting white blood cells (e.g., leukemia)?",
      },
      {
        question:
          "Have you undergone a whole blood transfusion within the last 20 days?",
      },
      {
        question:
          "Do you have a past medical history of bone marrow transplant?",
      },
      {
        question: "Do you have a past medical history of stem cell transplant?",
      },
    ],
  },
  serv00229: {
    serviceId: "serv00229",
    serviceName: "NUTRIGENOMICS (NGx) PRECISION MEDICINE SERVICES",
    suitable: [
      {
        question:
          "Do you have a family history of certain health conditions like metabolic disorders?",
      },
      { question: "Are you struggling with chronic health issues?" },
      {
        question:
          "Are you interested in preventive health and personalized dietary recommendations based on your genetic makeup?",
      },
      {
        question:
          "Do you experience unexplained food sensitivities or intolerances?",
      },
      {
        question:
          "Are you struggling to maintain optimal cholesterol, blood sugar, or other metabolic markers despite lifestyle changes?",
      },
    ],
    unsuitable: [
      {
        question:
          "Have you had a history of blood cancer affecting white blood cells (e.g., leukemia)?",
      },
      {
        question:
          "Have you undergone a whole blood transfusion within the last 20 days?",
      },
      {
        question:
          "Do you have a past medical history of bone marrow transplant?",
      },
      {
        question: "Do you have a past medical history of stem cell transplant?",
      },
    ],
  },
};

export const ziplanerxBookData = [
  {
    logo: "uil uil-stethoscope-alt icons",
    title: "Certified Practitioners",
    description:
      "Our extensive network of certified practitioners are accredited, governed and regulated by major councils like GMC and GPhC.",
  },
  {
    logo: "uil uil-star icons",
    title: "Highly rated by Customers like you",
    description:
      "Our network partners strive for quality, empathy and consistency of services driving high customer ratings!",
  },
  {
    logo: "ri ri-lightbulb-flash-line icons",
    title: "Modern frictionless experiences",
    description:
      "At ZipLaneRx, we pride in bringing frictionless experiences and telemedicine innovations to ensure each service interactions for our customers and practitioners are meaningful.",
  },
];

export const serviceLogoCarousel = [
  [
    {
      src: "assets/images/ServiceLogos/AestheticClinic.png",
      title: "Aesthetic Clinic",
    },
    {
      src: "assets/images/ServiceLogos/BioChemistryScreening.png",
      title: "Bio-Chemistry Screening",
    },
    {
      src: "assets/images/ServiceLogos/Contraception.png",
      title: "Contraception",
    },
    {
      src: "assets/images/ServiceLogos/EndocrynologyScreening.png",
      title: "Endocrynology Screening",
    },
    {
      src: "assets/images/ServiceLogos/GeneralHealth.png",
      title: "General Health",
    },
    {
      src: "assets/images/ServiceLogos/GeneralHealthScreening.png",
      title: "General Health Screening",
    },
    {
      src: "assets/images/ServiceLogos/GeneticHealthScreening.png",
      title: "Genetic Health Screening",
    },
    {
      src: "assets/images/ServiceLogos/HealthScreening.png",
      title: "Health Screening",
    },
    {
      src: "assets/images/ServiceLogos/HealthyLiving.png",
      title: "Healthy Living",
    },
    {
      src: "assets/images/ServiceLogos/HematologyScreening.png",
      title: "Hematology Screening",
    },
    {
      src: "assets/images/ServiceLogos/InfectionManagment.png",
      title: "Infection Management",
    },
    {
      src: "assets/images/ServiceLogos/LifestyleAndNutritionScreening.png",
      title: "Lifestyle And Nutrition Screening",
    },
    {
      src: "assets/images/ServiceLogos/MedicalWeightLossClinic.png",
      title: "Medical Weight Loss Clinic",
    },
    {
      src: "assets/images/ServiceLogos/MicrobiologyScreening.png",
      title: "Microbiology Screening",
    },
    {
      src: "assets/images/ServiceLogos/PainManagement.png",
      title: "Pain Management",
    },
    {
      src: "assets/images/ServiceLogos/SexualHealth.png",
      title: "Sexual Health",
    },
    {
      src: "assets/images/ServiceLogos/SexualHealthScreening.webp",
      title: "Sexual Health Screening",
    },
    {
      src: "assets/images/ServiceLogos/SkinTreatment.png",
      title: "Skin Treatment",
    },
    {
      src: "assets/images/ServiceLogos/TravelHealth.png",
      title: "Travel Health",
    },
    {
      src: "assets/images/ServiceLogos/Vaccination.png",
      title: "Vaccination",
    },
  ],
];

export const faqQuestions = [
  {
    id: 1,
    question: "I forgot my password, will I be able to recover my account?",
    answer:
      "Yes, of course. As a patient or customer, the easiest way to recover your account would be to reset your password using the forgot password at www.ziplanerx.com/patient/login.",
  },
  {
    id: 2,
    question: "How can I make changes to my profile?",
    answer:
      "ZiplaneRx makes it easy for you to manage your profile. As a patient or customer, you can login to Ziplanerx at www.ziplanerx.com/patient/login. You can edit the left panel to make updates to your profile. Don’t forget to save!",
  },
  {
    id: 3,
    question: "What are the acceptable payment methods at ZiplaneRx?",
    answer:
      "ZiplaneRx provides industry leading payment methods for our customers and patients convenience. ZiplaneRx accepts Credit Cards, Debit Cards, Digital Wallet Payments, direct credit and debit through BACS, Link, Revolute and more! The only payment we don’t take is cash!",
  },
  {
    id: 4,
    question: "How secure is ZiplaneRx?",
    answer:
      "ZiplaneRx offers a highly secured experience for our customers, patients and practitioners. This includes an industry leading authentication using oAuth2.0 and end-to-end encryption of data at rest and in-motion. ZiplaneRx is PCI DSS 4.0 compliant for secured payments and ICO compliant for Data Protection act 2019.",
  },
  {
    id: 5,
    question: "How can I see my past invoices?",
    answer:
      "ZiplaneRx makes it easy for you to access your past invoices. As a patient, if you have created a login, all your invoices can be accessed once you login to ZiplaneRx at www.ziplanerx.com/login. The center pane has all your invoices neatly arranged by appointments. Your invoices are also real-time emailed to you post every booking appointment.",
  },
  {
    id: 6,
    question: "How can I see my appointments?",
    answer:
      "ZiplaneRx makes it easy for you to access your appointments. As a patient, if you have created a login, all your appointments can be accessed once you login to ZiplaneRx at www.ziplanerx.com/login. The center pane has all your past, current and future appointments neatly arranged by dates. Your appointment details are also real-time emailed to you post every booking.",
  },
  {
    id: 7,
    question: "How can I see my orders and tracking number?",
    answer:
      "ZiplaneRx makes it easy for you to access your orders and provides real-time tracking. As a customer, if you have created a login, all your orders can be accessed once you login to ZiplaneRx at www.ziplanerx.com/login. The center pane has all your past, current and future orders neatly arranged by dates and a tracking number. Your order details are also real-time emailed to you post every order placement along with the tracking number.",
  },
  {
    id: 8,
    question: "I am not in the UK, can I use ZiplaneRx?",
    answer:
      "ZiplaneRx is currently not accepting practitioners outside the UK. However, you can make an appointment to a UK practitioner from anywhere in the world.",
  },
  {
    id: 9,
    question: "How can I contact ZiplaneRx?",
    answer:
      "You can reach out to us for any questions by emailing us at contact@ZiplaneRx.com.",
  },
];

export const offerPatients = {
  0: {
    key: "Convenience",
    data: [
      "Select from an extensive range of 300 NHS and Private specialized services. Seasonal, Travel, Weight management, Aesthetics, Genomics, Pathology Hair restoration services and more!",
      "Book NHS and Private services, all through the comfort of your phone, tablet or a computer. Click, Pay and Book, it’s that simple!",
      "ZiplaneRx offers industry leading payment methods to support easy checkouts. We make it Secured and Seamless!",
      "An intuitive patient portal and real-time notifications. We want to ensure a seamless pre-at-post service experience!",
    ],
  },
  1: {
    key: "Accessibility",
    data: [
      "Healthcare needs are non-cyclical. ZiplaneRx is available - 24/7 and 365 days every year for to take and process your needs when they arise. No waiting, no anxiety!",
      "Features that aid differently abled patients to seamlessly book appointments. Digitization for all including accessibility features!",
    ],
  },
};

export const offerPractitioners = {
  0: {
    key: "Versatile Platform",
    data: [
      "A frictionless and intelligent digital onboarding experience in less than 3 minutes. We’re obsessed with making things simple!",
      "Your own personalized Digital Web store with NHS and Google Reviews. As unique as your brand!",
      "Integrated Search Engine Optimization on us. We make your brand outreach easy!",
    ],
  },
  1: {
    key: "Secured by Design",
    data: [
      "Industry leading oAuth 2.0. Securing customers and practitioners is our number 1 priority!",
      "End to end encryption. We design and build with security as a foundation!",
      "ICO compliant. We take security seriously!",
      "PCI DSS 4 Compliant. Extend the security from the platform to payments!",
    ],
  },
  2: {
    key: "Sophisticated Automation",
    data: [
      "Integrated Calendar Bookings. We’re also obsessed with automations so you can focus on delivering exceptional services.",
      "Real-time notifications. We make it easy for you to not miss an appointment booking!",
      "Brilliant real-time insights. Measure and pivot to what matters for the growth of your business!",
      "Integrated and automated Invoicing and reporting. Click, View, Print, Email — ZiplaneRx got you covered!",
      "Integrated Practitioner advisory and Social Integrations. Learning, sharing, and engaging — we make it easy!",
      "Self-service Customer, Service, and Product offerings. We build with simplicity in mind so you never need a technologist to digitize!",
    ],
  },
};

export const multiAppointmentServices = [
  "serv00199",
  "serv00200",
  "serv00201",
  "serv00202",
  "serv00203",
  "serv00204",
  "serv00205",
  "serv00206",
  "serv00216",
  "serv00217",
  "serv00218",
  "serv00219",
  "serv00220",
  "serv00221",
  "serv00222",
  "serv00223",
  "serv00224",
  "serv00225",
  "serv00226",
];
