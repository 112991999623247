import React from "react";
import { Link } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";

function ProductsTable({ data }) {
  return (
    <div className="row text-start" style={{ fontSize: "small" }}>
      {!data || data.length === 0 ? (
        <div className="col-12" style={{ marginTop: 5, marginBottom: 10 }}>
          <h5>Your Products Cart is Empty!</h5>
        </div>
      ) : (
        <div className="col-12" style={{ marginTop: 0, marginBottom: 0 }}>
          <h5>Your Products Cart</h5>
        </div>
      )}

      <div className="col-12">
        <div className="table-responsive bg-white shadow rounded">
          <table className="table table-center table-padding mb-0">
            <thead>
              <tr>
                <th className="border-bottom p-3" style={{ minWidth: "300px" }}>
                  Product
                </th>
                <th className="border-bottom p-3">Price</th>
                <th className="border-bottom p-3">Qty</th>
                <th className="border-bottom p-3">Subtotal</th>
                <th className="border-bottom p-3">VAT-Code</th>
                <th className="border-bottom p-3">VAT</th>
                <th className="border-bottom p-3">Total</th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index}>
                    {/*Product*/}
                    <td className="shop-list p-3">{item.product_nm}</td>
                    {/*Price*/}
                    <td className="shop-list p-3">£ {item.product_cost_amt}</td>
                    {/*Quantity*/}
                    <td className="shop-list p-3">{item.order_quantity}</td>
                    {/*Subtotal*/}
                    <td className="shop-list font-weight-bold p-3">
                      £{" "}
                      {Math.round(
                        item.product_cost_amt * item.order_quantity * 100,
                      ) / 100}
                    </td>
                    {/*VAT Code*/}
                    <td className="shop-list p-3">
                      {item.order_metadata.vatCode}
                    </td>
                    {/*VAT AMT*/}
                    <td className="shop-list font-weight-bold p-3">
                      £ {item.zlrx_vat_amt}
                    </td>
                    {/*Item Total*/}
                    <td className="shop-list font-weight-bold p-3">
                      £ {item.product_ttl_amt}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ProductsTable;
