import React, { useEffect, useRef, useState } from "react";
import CartInputField from "../fields/CartInputField";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import Radar from "radar-sdk-js";
import { orderDeliveryMethods } from "../../data/data";

function BilledUser({
  firstName,
  firstNameChange,
  firstNameValidation,
  lastName,
  lastNameChange,
  lastNameValidation,
  emailAddress,
  emailAddressChange,
  emailValidation,
  phoneNumber,
  phoneNumberChange,
  phoneNumberValidation,
  birthdate,
  birthdateChange,
  birthdateValidation,
  addressLine1,
  addressLine1Change,
  addressLine1Validation,
  addressLine2,
  addressLine2Change,
  postalCode,
  postalCodeChange,
  postalCodeValidation,
  city,
  cityChange,
  cityValidation,
  serviceCart,
  productCart,
  deliveryFee,
  setDeliveryFee,
  getDeliveryFee,
  delivery,
  setDelivery,
}) {
  // Variables
  const autocompleteRef1 = useRef(null);
  const autocompleteRef2 = useRef(null);
  let emailList =
    serviceCart && serviceCart.length > 0
      ? serviceCart.map((item) => item.emailId)
      : [];
  emailList = [...new Set(emailList)];

  let customerNames =
    serviceCart && serviceCart.length > 0
      ? serviceCart.map((item) => `${item.firstName} ${item.lastName}`)
      : [];
  customerNames = [...new Set(customerNames)];
  const [customerName, setCustomerName] = useState(null);

  // Use Effect
  useEffect(() => {
    if (serviceCart && serviceCart.length > 0) {
      emailAddressChange({ target: { value: serviceCart[0].emailId } });
      firstNameChange({ target: { value: serviceCart[0].firstName } });
      lastNameChange({ target: { value: serviceCart[0].lastName } });
      setCustomerName(`${serviceCart[0].firstName} ${serviceCart[0].lastName}`);
    }
  }, [serviceCart]);

  // Radar
  useEffect(() => {
    Radar.initialize(process.env.REACT_APP_RADAR_PK);
    try {
      autocompleteRef1.current = Radar.ui.autocomplete({
        container: "autocomplete_city",
        width: "1200px",
        // responsive: true,
        layers: ["fine"],
        minCharacters: 3,
        onSelection: (address) => {
          if (address.city) {
            cityChange({ target: { value: address.city } });
          }

          if (address.postalCode) {
            postalCodeChange({ target: { value: address.postalCode } });
          }
        },
      });

      autocompleteRef2.current = Radar.ui.autocomplete({
        container: "autocomplete_postal",
        width: "1200px",
        responsive: true,
        layers: ["postalCode"],
        minCharacters: 3,
        limit: 10,
        onSelection: (address) => {
          if (address.city) {
            cityChange({ target: { value: address.city } });
          }

          if (address.postalCode) {
            postalCodeChange({ target: { value: address.postalCode } });
          }
        },
      });
    } catch (err) {}

    return () => {
      autocompleteRef1.current?.remove();
      autocompleteRef2.current?.remove();
    };
  }, []);

  // Handler
  const handleNameChange = (e) => {
    setCustomerName(e.target.value);
    firstNameChange({
      target: { value: customerNames[e.target.value].split()[0] },
    });
    lastNameChange({
      target: { value: customerNames[e.target.value].split()[-1] },
    });
  };

  return (
    <div className="row">
      <div
        className="pt-2 mt-2 pb-2 rounded"
        style={{
          backgroundColor: "#fff",
          width: "94%",
          marginLeft: "3%",
          marginRight: "3%",
        }}
      >
        <div className="row rounded">
          {/*Shipping*/}
          {productCart && Object.keys(productCart).length > 0 && (
            <div className="w-lg-50">
              <div className="form-group d-flex px-2 text-start">
                <label className="pt-2 me-2">Delivery Method</label>
                <select
                  className="form-control mt-1 h-25 py-0"
                  style={{ width: "60%" }}
                  value={delivery}
                  onChange={(e) => {
                    setDelivery(e.target.value);
                    setDeliveryFee(getDeliveryFee(e.target.value));
                  }}
                >
                  <option defaultValue="default" disabled={true}>
                    Select Delivery Method
                  </option>
                  {orderDeliveryMethods
                    ? orderDeliveryMethods.map((met) => {
                        return (
                          <option key={met.value} value={met.value}>
                            {`${met.name}: ${met.desc}`}
                          </option>
                        );
                      })
                    : []}
                </select>
              </div>
            </div>
          )}
          {/*Email Address:*/}
          <div className="w-lg-50">
            {serviceCart && serviceCart.length > 0 ? (
              <div className="form-group d-flex px-2 text-start">
                <label className="pt-2 me-2">Select Email</label>
                <select
                  name="email"
                  id="email"
                  className="form-control mt-1 h-25 py-0"
                  style={{ width: "60%" }}
                  required={true}
                  placeholder="Your email :*"
                  value={emailAddress}
                  onChange={emailAddressChange}
                  onSelect={emailAddressChange}
                >
                  <option value="" disabled>
                    -- Select Email --
                  </option>
                  {emailList.map((name, index) => (
                    <option key={index} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className="form-group d-flex px-2 text-start col-12">
                <label className="pt-2 me-2 col-4 col-lg-2 text-start">
                  Email
                </label>
                <div className="col-8 col-lg-10">
                  <CartInputField
                    type="email"
                    required
                    placeholder="Your email :*"
                    value={emailAddress}
                    onChange={emailAddressChange}
                    validation={emailValidation}
                    ariaLabel="Email Address"
                  />
                </div>
              </div>
            )}
          </div>
          {/*Customer Name:*/}
          <div className="w-lg-50">
            {serviceCart && serviceCart.length > 0 ? (
              <div className="form-group d-flex px-2 text-start">
                <label className="pt-2 me-2">Select Name</label>
                <select
                  name="name"
                  id="name"
                  className="form-control mt-1 h-25 py-0"
                  style={{ width: "60%" }}
                  required={true}
                  placeholder="Your Name :*"
                  value={customerName}
                  onChange={handleNameChange}
                  onSelect={handleNameChange}
                >
                  <option value="" disabled>
                    -- Select Name --
                  </option>
                  {customerNames.map((name, index) => (
                    <option key={index} value={index}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className="row px-0 mx-0">
                <div className="form-group d-flex px-2 text-start col-12 col-lg-6">
                  <label className="pt-2 me-2 col-4">First Name</label>
                  <div className="col-8">
                    <CartInputField
                      type="text"
                      required
                      placeholder="First Name :*"
                      value={firstName}
                      onChange={firstNameChange}
                      validation={firstNameValidation}
                      ariaLabel="First Name"
                    />
                  </div>
                </div>
                <div className="form-group d-flex px-2 text-start col-12 col-lg-6">
                  <label className="pt-2 me-2 col-4">Last Name</label>
                  <div className="col-8">
                    <CartInputField
                      type="text"
                      required
                      placeholder="Last Name :*"
                      value={lastName}
                      onChange={lastNameChange}
                      validation={lastNameValidation}
                      ariaLabel="Last Name"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {/*Phone Number and Birthdate*/}
          <div className="w-lg-50">
            {(!serviceCart || serviceCart.length === 0) &&
              productCart &&
              Object.keys(productCart).length > 0 && (
                <div className="row px-0 mx-0">
                  {/*Phone Number*/}
                  <div className="form-group d-flex px-2 text-start col-12 col-lg-6">
                    <label className="pt-2 me-2 col-4">Phone No.</label>
                    <div className="col-8">
                      <CartInputField
                        type="tel"
                        required
                        placeholder="Your Phone* : 01223334444"
                        value={phoneNumber}
                        onChange={phoneNumberChange}
                        validation={phoneNumberValidation}
                        ariaLabel="Phone Number"
                      />
                    </div>
                  </div>
                  {/*Birthdate*/}
                  <div className="form-group d-flex px-2 text-start col-12 col-lg-6">
                    <label className="pt-2 me-2 col-4">Birthdate</label>
                    <div className="col-8">
                      <div
                        className="py-2 px-2 border rounded bg-soft-dark"
                        style={{ borderWidth: "medium" }}
                      >
                        <DatePicker
                          className="rounded border-0 w-75"
                          placeholderText="Enter Birthdate"
                          dateFormat={[
                            "dd MMM, yyyy",
                            "dd MMMM, yyyy",
                            "ddMMyyyy",
                            "dd-MM-yyyy",
                            "dd/MM/yyyy",
                          ]}
                          autofocus={false}
                          selected={
                            birthdate
                              ? moment(birthdate, "YYYY-MM-DD").toDate()
                              : null
                          }
                          onChange={birthdateChange}
                          onKeyDown={(e) =>
                            e.key === "Enter" && e.preventDefault()
                          }
                          aria-label="Enter Birthdate"
                          aria-required={true}
                        />
                      </div>

                      {birthdateValidation && !birthdateValidation.valid && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "smaller" }}
                        >
                          {birthdateValidation.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
          {/*Address Line 1 and Line 2*/}
          <div className="w-lg-50">
            {productCart && Object.keys(productCart).length > 0 && (
              <div className="row px-0 mx-0">
                {/*Address Line 1*/}
                <div className="form-group d-flex px-2 text-start col-12 col-lg-6">
                  <label className="pt-2 me-2 col-4">Address Line 1</label>
                  <div className="col-8">
                    <CartInputField
                      type="text"
                      required
                      placeholder="Address Line 1 :*"
                      value={addressLine1}
                      onChange={addressLine1Change}
                      validation={addressLine1Validation}
                      ariaLabel="Address Line 1"
                    />
                  </div>
                </div>
                {/*Address Line 2*/}
                <div className="form-group d-flex px-2 text-start col-12 col-lg-6">
                  <label className="pt-2 me-2 col-4">Line 2</label>
                  <div className="col-8">
                    <CartInputField
                      type="text"
                      placeholder="Address Line 2 :"
                      value={addressLine2}
                      onChange={addressLine2Change}
                      ariaLabel="Address Line 2"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {/*City and Zip*/}
          <div className="w-lg-50">
            {productCart && Object.keys(productCart).length > 0 && (
              <div className="row px-0 mx-0">
                {/*Postal Code*/}
                <div className="form-group d-flex px-2 text-start col-12 col-lg-6">
                  <label className="pt-2 me-2 col-4">Postal Code</label>
                  <div className="col-8">
                    <CartInputField
                      type="text"
                      id="autocomplete_postal"
                      required
                      placeholder="Postal Code :*"
                      value={postalCode}
                      onChange={postalCodeChange}
                      validation={postalCodeValidation}
                      ariaLabel="Postal Code"
                    />
                  </div>
                </div>
                {/*City*/}
                <div className="form-group d-flex px-2 text-start col-12 col-lg-6">
                  <label className="pt-2 me-2 col-4">City</label>
                  <div className="col-8">
                    <CartInputField
                      type="text"
                      id="autocomplete_city"
                      placeholder="City :"
                      value={city}
                      onChange={cityChange}
                      validation={cityValidation}
                      ariaLabel="City"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {/*Pick Up Location*/}
          <div className="w-100">
            {productCart &&
              Object.keys(productCart).length > 0 &&
              delivery === "free" && (
                <div className="row px-0 mx-0">
                  {/*Postal Code*/}
                  <div className="form-group d-flex px-2 text-start col-12">
                    <label className="pt-2 me-2 col-3">Pick Up Location</label>
                    <div className="col-9">
                      Mango Pharmacy, 98 High St, Edgware HA8 7HF, United
                      Kingdom
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BilledUser;
