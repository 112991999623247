import React from "react";

function TotalSummary({ deliveryFee, servicesTotal, productsTotal }) {
  return (
    <div className="row">
      <div className="w-100 ms-auto pt-2">
        <div className="table-responsive bg-white rounded shadow">
          <table className="table table-center table-padding mb-0">
            <tbody>
              {/*Services Total*/}
              <tr>
                <td className="text-start fw-bold p-3">Services Total</td>
                <td className="text-start p-3">
                  {servicesTotal ? `£ ${servicesTotal}` : "-"}
                </td>
              </tr>
              {/*Products Total*/}
              <tr>
                <td className="text-start fw-bold p-3">Products Total</td>
                <td className="text-start p-3">
                  {productsTotal ? `£ ${productsTotal}` : "-"}
                </td>
              </tr>
              {/*Delivery Fee*/}
              <tr>
                <td className="text-start fw-bold p-3">Delivery Fee</td>
                <td className="text-start p-3">{`£ ${deliveryFee}`}</td>
              </tr>
              {/*Total*/}
              <tr>
                <td className="text-start fw-bold p-3">Total</td>
                <td className="text-start p-3">
                  £{" "}
                  {Math.round(
                    ((servicesTotal || 0) +
                      (productsTotal || 0) +
                      (deliveryFee || 0)) *
                      100,
                  ) / 100}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TotalSummary;
