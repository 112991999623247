import React from "react";
import moment from "moment";

function ServiceTable({ data }) {
  return (
    <div className="row text-start" style={{ fontSize: "small" }}>
      {!data || data.length === 0 ? (
        <div className="col-12" style={{ marginTop: 2, marginBottom: 10 }}>
          <h5>Your Services Cart is Empty!</h5>
        </div>
      ) : (
        <div className="col-12" style={{ marginTop: 0, marginBottom: 0 }}>
          <h5>Your Services Cart</h5>
        </div>
      )}

      <div className="col-12">
        <div className="table-responsive bg-white shadow rounded">
          <table className="table table-center table-padding mb-0">
            <thead>
              <tr>
                <th className="border-bottom p-3" style={{ minWidth: "300px" }}>
                  Service
                </th>
                <th className="border-bottom p-3" style={{ minWidth: "160px" }}>
                  Name
                </th>
                <th className="border-bottom p-3" style={{ minWidth: "160px" }}>
                  Practitioner
                </th>
                <th className="border-bottom p-3" style={{ minWidth: "160px" }}>
                  Address
                </th>
                <th className="border-bottom p-3" style={{ minWidth: "160px" }}>
                  Phone
                </th>
                <th
                  className="border-bottom p-3"
                  style={{ minWidth: "fit-content" }}
                >
                  Date
                </th>
                <th
                  className="border-bottom p-3"
                  style={{ minWidth: "fit-content" }}
                >
                  Time
                </th>
                <th
                  className="border-bottom p-3"
                  style={{ minWidth: "fit-content" }}
                >
                  Total
                </th>
              </tr>
            </thead>

            <tbody>
              {data?.map((appt, index) => {
                return (
                  <tr key={index}>
                    {/*Service*/}
                    <td className="p-3">{appt.srvc_nm}</td>
                    {/*Name*/}
                    <td className="p-3">
                      {`${appt.appointment_metadata.firstName} ${appt.appointment_metadata.lastName}`}
                    </td>
                    {/*Practitioner*/}
                    <td className="shop-list p-3">
                      {appt.appointment_metadata.organization.organizationName}
                    </td>
                    <td className="shop-list p-3">
                      {`${appt.appointment_metadata.organization.address1}, ${appt.appointment_metadata.organization.city}, ${appt.appointment_metadata.organization.postCode}`}
                    </td>
                    <td className="shop-list p-3">
                      {appt.appointment_metadata.organization.phone}
                    </td>
                    {/*Date*/}
                    <td className="shop-list p-3">
                      {moment(appt.appointment_start_dttm).format(
                        "MMM Do YYYY",
                      )}
                    </td>
                    {/*Time*/}
                    <td className="shop-list p-3">
                      {moment(appt.appointment_start_dttm).format("HH:mm")}
                    </td>
                    {/*Total*/}
                    <td className="shop-list p-3">£{appt.srvc_ttl_amt}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ServiceTable;
