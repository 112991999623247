import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import "tiny-slider/dist/tiny-slider.css";
import Header from "../../components/header/header";
import moment from "moment";
import {
  getOrderProduct,
  postOrderProductPayment,
} from "../../utils/backoffice.service";
import ProductInvoice from "../../components/invoice/product-invoice";

export default function ProductsInvoicePage({
  cart,
  removeFromCart,
  clearCart,
}) {
  // Variables
  const [searchParams, setSearchParams] = useSearchParams();
  let orderId = searchParams.get("orderId");
  let payment_intent = searchParams.get("payment_intent");
  let payment_intent_client_secret = searchParams.get(
    "payment_intent_client_secret",
  );
  let redirect_status = searchParams.get("redirect_status");

  const [orderData, setOrderData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [showInvoice, setShowInvoice] = useState(false);

  // Use Effect
  useEffect(() => {
    if (orderId) {
      getOrderProduct(orderId).then((response) => {
        if (response.data) {
          setOrderData(response.data);
        }
      });
    }
  }, [orderId]);

  useEffect(() => {
    if (orderData && !paymentData) {
      let payload = orderData;
      payload.productOrderId = payload.id;
      payload.id = null;
      postOrderProductPayment(payload).then((response) => {
        if (response.data) {
          setPaymentData(response.data);
          setShowInvoice(true);
          clearCart();
        }
      });
    }
  }, [orderData]);

  return (
    <Fragment>
      <Header />
      <section className="bg-half-170 d-table w-100">
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "#202942" }}
        >
          <div className="container">
            <h1 style={{ opacity: "0" }}>ZipLaneRx -- Invoice</h1>
            <div className="row mt-5 justify-content-center">
              <div className="col-12">
                <div className="section-title text-center">
                  <h3
                    className="sub-title mb-4 text-light"
                    style={{ marginTop: 100, opacity: 0.7 }}
                  >
                    {orderData?.firstName
                      ? `Here is your Invoice ${orderData.firstName}!`
                      : "Here is your Invoice!"}
                  </h3>
                  <p className="para-desc mx-auto text-white">via ZipLaneRx</p>

                  <ul className="list-unstyled mt-4">
                    <li className="list-inline-item date text-white">
                      <i className="mdi mdi-calendar-check"></i>{" "}
                      {moment(new Date()).format("MMM Do, YYYY")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {showInvoice && paymentData ? (
                <ProductInvoice data={orderData} id="invoice" />
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
