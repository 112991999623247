// two-field-search
import React from "react";
import { Link } from "react-router-dom";
import { RiHome2Line, RiPrinterLine } from "react-icons/ri";
import moment from "moment";
import { capitalize } from "@mui/material";
import ServiceTable from "./ServiceTable";
import ProdcutsTable from "./ProdcutsTable";
import TotalSummary from "../cart/TotalSummary";

const Invoice = ({ data, bookingId, paymentIntentId }) => {
  let appointments = data?.services?.appointments;
  let orders = data?.products?.orders;
  return (
    <div className="col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-lg-10">
            <div className="card border-0 rounded shadow px-4 py-5">
              <div className="row-mb-0 text-start">
                <div className="col px-0 mx-0">
                  <img
                    src={"assets/images/logo-icon.png"}
                    height="75"
                    alt="ZipLaneRx -- Redefining healthcare access and practitioner success!"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-8 col-md-6 text-start">
                  <h5 className="mt-0 pt-0">ZiplaneRx</h5>
                  <h6 className="mt-1 pt-2">Invoice</h6>
                  <small className="text-black mb-0">
                    <small className="mb-0 text-muted">Invoice No. : </small>
                    {bookingId}
                    <br />
                    <small className="mb-0 text-muted">Invoice Dt. : </small>
                    {moment(data.booked_dttm).format("MMM Do YYYY")}
                    <br />
                    <small className="mb-0 text-muted">Name : </small>
                    {capitalize(data.payeeCustomer.customer_name)}
                    <br />
                    <small className="mb-0 text-muted">Email : </small>
                    {data.payeeCustomer.customer_email}
                    <br />
                    <small className="mb-0 text-muted">Phone : </small>
                    {data.payeeCustomer.customer_phone}
                  </small>
                </div>
              </div>

              <div className="pt-2 border-top">
                <div className="invoice-table pb-4">
                  <ServiceTable data={appointments} />
                  <br />
                  <ProdcutsTable data={orders} />
                  <div className="row border-top mt-2">
                    <div className="col-lg-4 col-md-5 ms-auto">
                      <TotalSummary
                        productsTotal={data?.products.total}
                        servicesTotal={data?.services.total}
                        deliveryFee={data?.deliveryFee}
                      />
                    </div>
                  </div>
                </div>

                <div className="border-top pt-4">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="text-sm-start text-muted text-center">
                        <small className="mb-0">
                          Customer Service :{" "}
                          <Link
                            to="tel:+152534-468-854"
                            className="text-warning"
                          >
                            contact@ziplanerx.com
                          </Link>
                        </small>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="text-sm-end text-muted text-center">
                        <small className="mb-0">
                          <Link to="/aboutus/terms" className="text-primary">
                            Terms & Conditions
                          </Link>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end mt-4 pt-2">
              <Link
                to="/home"
                onClick={() => window.open("/home", "_self")}
                className="btn btn-soft-primary d-print-none"
                style={{ margin: 5 }}
              >
                <RiHome2Line /> Home
              </Link>
              <Link
                to="#"
                onClick={() => window.print()}
                className="btn btn-soft-primary d-print-none"
                style={{ margin: 5 }}
              >
                <RiPrinterLine /> Print
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
